import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Block from './Block'

interface Props {
  label: string
  url: string
  icon?: string
  heading: string
  children: React.ReactNode
}

export const ReadNext = (props: Props) => (
  <ReadNextContainer className="layout-index">
    <Block>
      <h6>{props.label}</h6>
      <div className="icon">
        <img src={props.icon} aria-hidden="true" />
      </div>
      <h2>
        <Link to={props.url}>{props.heading}</Link>
      </h2>
      <div>{props.children}</div>
    </Block>
  </ReadNextContainer>
)

const ReadNextContainer = styled.div`
  padding: 64px 0;
  text-align: center;
  border-top: 1px rgba(0, 0, 0, 0.05) solid;

  h2 {
    margin-top: 8px;
  }

  h2,
  p {
    margin-left: auto;
    margin-right: auto;
    max-width: 740px;
  }

  h2 {
    a {
      border-radius: 12px;
      padding: 12px 16px 4px;
      text-decoration: underline;

      &:hover {
        background: #ebecf8;
        text-decoration: none;
      }
    }
  }

  //see _breakpoints.css for reference
  @media screen and (min-width: 737px) {
    padding: 92px 0;
  }
`
