import React from 'react'
import styled from 'styled-components'
import Block from '../components/Block'
import theme from '../theme'

interface Props {
  title?: string
  description?: string | React.ReactNode
  marginBottom?: string
}

/**
@deprecated
 */
export function LeadHeading({ title, description, marginBottom }: Props) {
  return (
    <LeadHeadingWrapper marginBottom={marginBottom}>
      <Block size="medium">
        <h2>{title}</h2>
        {description && <div className="description">{description}</div>}
      </Block>
    </LeadHeadingWrapper>
  )
}

const LeadHeadingWrapper = styled.div<Props>`
  margin: 0 auto
    ${props =>
      props.marginBottom ? theme.space[props.marginBottom] : theme.space[8]};
  text-align: center;

  h2 {
    color: ${theme.colors.heading};
    letter-spacing: -0.05rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 3rem;
    letter-spacing: -0.05rem;
    line-height: 1.2;

    @media screen and (max-width: ${theme.breakpoints['medium']}) {
      font-size: 2.25rem;
    }
  }

  .description {
    max-width: 640px;
    margin: 0 auto;
  }

  div @media screen and (max-width: ${theme.breakpoints['medium']}) {
    margin-bottom: ${theme.space[8]};
  }
`
