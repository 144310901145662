import React, { ReactNode } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

export function Feature({
  heading,
  label,
  image,
  imageAlt,
  type,
  headingMaxWidth,
  children,
}: {
  label?: string
  image: IGatsbyImageData
  imageAlt: string
  children: ReactNode
  type: string
  heading: string
  headingMaxWidth?: number
}) {
  return (
    <div className={type}>
      <div className="feature_text-container layout-index">
        <div className="label">{label}</div>
        <h3 style={{ maxWidth: headingMaxWidth }}>{heading}</h3>
        <div className="feature_text">{children}</div>
      </div>
      <div className="feature_image-container">
        <div className="feature_image-positioning_wrapper">
          <GatsbyImage image={image} alt={imageAlt} />
        </div>
      </div>
    </div>
  )
}

export default Feature
